import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import HeroesUrl from "../../../assets/wl/heroes.png";
import { ReactComponent as WalletIcon } from "../../../assets/wl/wallet.svg";
import { connectors, ConnProvider } from "../../../eth/connectors";
import { shortenAccountNumber, switchNetwork } from "../../../eth/ethUtils";
import { DefaultButton } from "../../buttons/DefaultButton";
import { ShadowButton } from "../../buttons/ShadowButton";
import { Popup } from "../../common/Popup";
import { isValidEmail } from "../../common/utils";
import { ConnectorModal } from "../../eth/ConnectorModal";
import Web3 from "web3";
import {useWeb3React} from "@web3-react/core";

function Input(props: { label: string, value: string, onChange: (value: string) => void, error?: string }) {
    return <div>
        <div className="text-nav text-dark">
            <FormattedMessage id={props.label} />
        </div>
        <div className="mt-3">
            <input type="text"
                className="block w-full text-lg py-3 px-5 border border-stroke-light bg-[#FAFAFA] rounded-2xl"
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
            />
        </div>
        <div className="text-rose-500">
            {props.error && <FormattedMessage id={props.error} />} &nbsp;
        </div>
    </div>
}

function WalletAddressInput(props: { value: string, onChange: (value: string) => void, error?: string }) {
    const [isWalletSelectorOpen, setWalletSelectorOpen] = useState<boolean>(false);

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();

    const onProviderSelected = async (provider: ConnProvider) => {
        const providerConn = connectors[provider];
        await deactivate();
        try {
            console.log('activate', providerConn);
            const activationResult = await activate(providerConn);
            console.log('activation result', activationResult);
        } catch (e) {
            console.log('activation error', e);
        }
    };

    useEffect(()=>{
        props.onChange(account||"");
        if (!account) {
            return;
        }
        if (!library) return;
        if (chainId != 5) {
            switchNetwork(library, 5);
        }
    }, [account, chainId, library]);
    
    return <div>
        {isWalletSelectorOpen&&<ConnectorModal
            onClose={()=>setWalletSelectorOpen(false)}
            onProviderSelected={onProviderSelected}
        />}
        <div className="text-nav text-dark">
            <FormattedMessage id="Wallet verbinden" />*
        </div>
        <div className="mt-3">
            {!account&&<div className="w-full text-lg py-3 px-5 border-4 border-orange rounded-2xl
            flex justify-center items-center gap-2 text-orange font-bold cursor-pointer
            " 
            style={{height: "60px"}}
            onClick={()=>setWalletSelectorOpen(true)}
            >
                <WalletIcon />
                <div><FormattedMessage id="Wallet verbinden" /></div>
            </div>}
            {account&&<div className="flex justify-between items-center" style={{height: "60px"}}>
                <div className="font-bold text-orange">{shortenAccountNumber(account)}</div>
                <div className="text-dark underline cursor-pointer"
                    onClick={()=>deactivate()}
                >
                    <FormattedMessage id="Verbindung aufheben" />
                </div>
            </div>}
        </div>
        <div className="text-rose-500">
            {props.error && <FormattedMessage id={props.error} />} &nbsp;
        </div>
    </div>;
}

export function WlForm() {
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [address, setAddress] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [success, setIsSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [emailError, setEmailError] = useState<string>("");
    const [codeError, setCodeError] = useState<string>("");
    const [addressError, setAddressError] = useState<string>("");

    useEffect(() => {
        const url = new URL(window.location.href);
        const wlcode = url.searchParams?.get("wlcode");
        if (wlcode) setCode(wlcode);
    }, []);

    const use = () => {
        if (isLoading) return;

        setIsSuccess(false);
        setError("");

        setEmailError("");
        setCodeError("");
        setAddressError("");

        if (!email.trim() || !isValidEmail(email.trim())) {
            setEmailError("Dies ist keine gültige E-Mail Adresse");
            return;
        }

        if (code.length != 10) {
            setCodeError("Dies ist kein gültiger Teilnahmecode");
            return;
        }

        if (!address.trim()) {
            setAddressError("Bitte verbinde dein Wallet");
            return;
        }

        setIsLoading(true);

        fetch(
            `/api/?action=use`,
            {
                method: "POST",
                body: JSON.stringify({
                    address, code, email
                })
            }
        )
            .then(resp => resp.json())
            .then(resp => {
                setIsLoading(false);
                if (resp["status"] === "success") {
                    setIsSuccess(true);
                    setCode("");
                } else if (resp["error"]) {
                    setError(resp["error"]);
                }
            });
    };

    return <div>
        <div className="flex justify-end">
            {success && <Popup width="450px" onClose={() => setIsSuccess(false)}>
                <div className="py-5 font-sofia-pro">
                    <div className="text-dark text-headline-3 text-align">
                        <FormattedMessage id="Anmeldung erfolgreich!" />
                    </div>
                    <div className="text-light-dark mt-4">
                        <FormattedMessage id="Trage dir gleich das Mint Datum in deinen Kalender ein, und halte die Augen hoffen." />
                    </div>
                    <div className="mt-6">
                        <DefaultButton onClick={() => setIsSuccess(false)} className="w-full">
                            <FormattedMessage id="Los geht's!" />
                        </DefaultButton>
                    </div>
                </div>
            </Popup>}
            {error && <Popup width="450px" onClose={() => setError("")}>
                <div className="py-5 font-sofia-pro">
                    <div className="text-dark text-headline-3 text-align">
                        <FormattedMessage id="Code nicht gefunden" />
                    </div>
                    <div className="text-light-dark mt-4">
                        <FormattedMessage id="Leider haben wir deinen Code nicht gefunden. Probiere es mit einem anderen Code, order warte auf den Start des Public Sale am 30.10.2022" />
                    </div>
                    <div className="mt-6">
                        <DefaultButton onClick={() => setError("")} className="w-full">
                            <FormattedMessage id="Zurück" />
                        </DefaultButton>
                    </div>
                </div>
            </Popup>}

            <div className="hidden md:flex items-center w-50 relative w-3/12 overflow-hidden">
                <div
                    className="absolute w-full h-full"
                    style={{
                        background: `url(${HeroesUrl})`,
                        backgroundPosition: "right center",
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        right: "-15%"
                    }}
                />
            </div>
            <div className="p-6 md:p-10 border border-stroke-light rounded-2xl w-full md:w-8/12">
                <div className="flex flex-col gap-4">
                    <Input label="E-Mail" value={email} onChange={setEmail} error={emailError} />
                    <Input label="Teilnahmecode" value={code} onChange={setCode} error={codeError} />
                    <WalletAddressInput value={address} onChange={setAddress} error={addressError} />
                    <ShadowButton className="block w-full mt-4" onClick={() => use()}>
                        {!isLoading && <FormattedMessage id="Absenden" />}
                        {isLoading && <div>...</div>}
                    </ShadowButton>
                </div>
            </div>
        </div>
        <div className="md:text-right text-light-dark mt-2">
            <FormattedMessage id="*Wir unterstützen die gängigen Wallets Metamask, Coinbase und Trustwallet." />
        </div>
    </div>
}
