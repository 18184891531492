import {ConnProvider} from "./connectors";

// export const getSavedProvider = (): ConnProvider|null => {
//     return window.localStorage.getItem("provider") as ConnProvider|null;
// };
//
// export const saveProvider = (name: ConnProvider|null) => {
//     if (!name) {
//         window.localStorage.removeItem("provider");
//         return;
//     }
//     window.localStorage.setItem("provider", name);
// };

export const toHex = (num: number) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

export const shortenAccountNumber = (account: string) => {
    return  (!account || account.length < 10) ? account :
        account.substring(0, 5) + '...' + account.substring(account.length-4, account.length);
};

export const switchNetwork = async (library: any, network: number) => {
    await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }]
    });
};
