import { HeroesImage } from "./HeroesImage";
import {FormattedMessage} from "react-intl";
import { ShadowButton } from "../buttons/ShadowButton";
import HeroesBlurredUrl from "../../assets/images/heroes/blurred-bg.png";
import { COINBASE_URL, DISCORD_URL, OPENSEA_LEARN_URL } from "../../config";
import { WlForm } from "./wl/WlForm";
import { useState } from "react";
import { Popup } from "../common/Popup";
import { DefaultButton } from "../buttons/DefaultButton";


export function HeroesTopSection(props: {onHowToOpen:()=>void}) {
    return <div className="px-6 xl:px-0 mx-auto my-6 max-w-full overflow-hidden md:overflow-visible" style={{maxWidth: "1440px"}}>
        <div className="flex flex-col-reverse gap-8 md:flex-row-reverse">      
            <div className="md:w-7/12">
                <WlForm />
            </div>
            <div className="md:w-5/12 relative">
                <div className="hidden md:block md:absolute h-full top-0" style={{
                    backgroundImage: `url(${HeroesBlurredUrl})`,
                    backgroundSize: `contain`,
                    backgroundRepeat: 'no-repeat',
                    left: `-20%`,
                    width: `120%`
                }} />
                <div className="md:absolute w-full left-0 top-0 z-10 flex flex-col md:justify-center" style={{height: `${9/10.7*100}%`}}>
                    <div className="text-active text-lg font-bold">
                        <FormattedMessage id="Voranmeldung" />
                    </div>
                    <div className="text-4xl md:text-7xl text-dark font-bold mt-3">
                        <FormattedMessage id="Castle Heroes" />
                    </div>
                    <div className="text-light-dark mt-3 font-sofia-pro">
                        <FormattedMessage id="Sichere dir jetzt dein Castle Heroes NFT" /> 
                    </div>
                    <div className="text-light-dark mt-8 font-sofia-pro">
                        <FormattedMessage id="Trage deine E-Mail, deinen Voranmeldungscode sowie deine ETH Wallet-Adresse ein, um beim Launch der Castle Heroes am 24.10.22 in der ersten Reihe zu sein." /> 
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
